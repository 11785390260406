import React from "react";
import { ListItem, VStack, Text, Heading, Link } from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons'

const ProjectCard = (props) => {
  return (
    <VStack>
      <ListItem>
        <Link href={props.project.links.deployed} isExternal>
          <Heading as="h5" size="lg">
            {props.project.title} <ExternalLinkIcon />
          </Heading>
        </Link>
        <Text>{props.project.summary}</Text>
        <Link href={props.project.links.github} isExternal>Github</Link>
      </ListItem>
    </VStack>
  );
};

export default ProjectCard;
