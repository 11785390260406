import React from "react";
import styled from "styled-components";
import {
  Heading,
  Flex,
  Text,
  Center,
  UnorderedList,
  ListItem,
  Link,
} from "@chakra-ui/react";

import {
  GithubOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  MailOutlined,
} from "@ant-design/icons";

const StyledUnorderedList = styled(UnorderedList)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  margin-left: 0;
`;

const Name = styled(Center)`
  padding-top: 12px;
`;

const Bio = styled(Center)`
  padding-top: 24px;
  padding-bottom: 24px;
  margin: 0px 10px;
`;

const LogoLinks = styled(Center)`
  padding-bottom: 12px;
`;

const Profile = () => {
  return (
    <Flex w="100%" h="100vh" direction="column" align="center" justify="center">
      <Name>
        <Heading as="h2" size="2xl">
          Ian Luchini
        </Heading>
      </Name>
      <Bio w="85%">
        <Text align="start">
          {/*  Some bio */}
        </Text>
      </Bio>
      <LogoLinks w="100%">
        <StyledUnorderedList styleType="none" w="100%">
          <ListItem>
            <Link href="https://github.com/bgmad" isExternal>
              <GithubOutlined style={{fontSize: "30px"}} />
            </Link>
          </ListItem>
          <ListItem>
            <Link href="https://twitter.com/IanLuchini" isExternal>
              <TwitterOutlined style={{fontSize: "30px"}} />
            </Link>
          </ListItem>
          <ListItem>
            <a href="mailto:iancarloluchini@gmail.com" >
              <MailOutlined style={{fontSize: "30px"}} />
            </a>
          </ListItem>
          <ListItem>
            <Link
              href="https://www.linkedin.com/in/ian-luchini-7738181b2/"
              isExternal
            >
              <LinkedinOutlined style={{fontSize: "30px"}} />
            </Link>
          </ListItem>
        </StyledUnorderedList>
      </LogoLinks>
    </Flex>
  );
};

export default Profile;
