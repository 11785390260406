import React, { useEffect } from "react";
import gsap from "gsap";
import styled from "styled-components";
import { Box } from "@chakra-ui/react";

const Background = styled(Box)`
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
`;

const IntroBackground = () => {
  const duration = 0.85;
  useEffect(() => {
    const tl = gsap.timeline();
    tl.from(".box-1", { x: -window.innerWidth, duration: duration, delay: 1, ease: "bounce.out" });
    tl.from(".box-2", { x: window.innerWidth, duration: duration, ease: "bounce.out" });
  }, []);

  return (
    <Background id="background-container" w="100%" bg="gray.200">
      <Box className="box-1" w="100%" h="25vh" bg="blue.200"></Box>
      <Box className="box-2" w="100%" h="25vh" bg="yellow.100"></Box>
      <Box className="box-1" w="100%" h="25vh" bg="blue.200"></Box>
      <Box className="box-2" w="100%" h="25vh" bg="yellow.100"></Box>
    </Background>
  );
};

export default IntroBackground;
