import React from "react";
import styled from 'styled-components';
import {
  VStack,
  Heading,
  Center,
  UnorderedList,
  ListItem,
  Text,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from "@chakra-ui/react";

const StyledAccordionButton = styled(AccordionButton)`
    display: flex;
    justify-content: space-between;
`;

const interests = [
  "Functional programming",
  "Vector math",
]

const Interests = () => {
  return (
    <VStack w="100%">
      <StyledAccordionButton>
        <Heading as="h2" size="md">
          Interests & working on
        </Heading>
        <AccordionIcon />
      </StyledAccordionButton>
      <AccordionPanel>
        <Center>
          <UnorderedList styleType="none" w="100%" spacing={2} align="center">
            {interests.map(interest => 
            <ListItem key={interest}>
              <Text>{interest}</Text>
            </ListItem>
            )
            }
          </UnorderedList>
        </Center>
      </AccordionPanel>
    </VStack>
  );
};

export default Interests;
