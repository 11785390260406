import React from "react";
import styled from "styled-components";
import {
  VStack,
  Heading,
  Center,
  UnorderedList,
  ListItem,
  Text,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

const StyledAccordionButton = styled(AccordionButton)`
    display: flex;
    justify-content: space-between;
`;

const MyStack = () => {
  return (
    <VStack w="100%">
      <StyledAccordionButton>
        <Heading as="h2" size="md">
          Current stack
        </Heading>
        <AccordionIcon />
      </StyledAccordionButton>
      <AccordionPanel>
        <Center>
          <UnorderedList styleType="none" w="100%" spacing={2} align="center">
            <ListItem>
              <Text>React</Text>
            </ListItem>
            <ListItem>
              <Text>Knex</Text>
            </ListItem>
            <ListItem>
              <Text>Node</Text>
            </ListItem>
            <ListItem>
              <Text>Express</Text>
            </ListItem>
            <ListItem>
              <Text>HTML5</Text>
            </ListItem>
            <ListItem>
              <Text>CSS</Text>
            </ListItem>
            <ListItem>
              <Text>Styled Components</Text>
            </ListItem>
            <ListItem>
              <Text>Ant Design</Text>
            </ListItem>
            <ListItem>
              <Text>Chakra UI</Text>
            </ListItem>
          </UnorderedList>
        </Center>
      </AccordionPanel>
    </VStack>
  );
};

export default MyStack;
