import React from "react";
import styled from "styled-components";
import { Accordion, AccordionItem } from "@chakra-ui/react";

import IntroBackground from "./components/IntroBackground";
import Profile from "./components/Profile";
import MyStack from "./components/MyStack";
import Interests from "./components/Interests";
import Projects from "./components/Projects";

const ContentContainer = styled.div`
padding: 0 30px;
margin-bottom: 300px;

@media (min-width: 900px) {
  padding: 0 100px;
}
@media (min-width: 1200px) {
  padding: 0 200px;
}
`;

function App() {
  return (
    <div>
      <Profile />
      <IntroBackground /> 
      <br />
      <ContentContainer>
        <Accordion allowMultiple>
          <AccordionItem>
            <MyStack />
          </AccordionItem>
          <AccordionItem>
            <Interests />
          </AccordionItem>
        </Accordion>
        <br />
        <Projects />
      </ContentContainer>
    </div>
  );
}

export default App;
