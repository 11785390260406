import React from "react";
import styled from "styled-components";
import {
  VStack,
  Heading,
  Center,
  UnorderedList,
  Flex,
  Divider,
} from "@chakra-ui/react";

import ProjectCard from "./ProjectCard";

const StyledUnorderedList = styled(UnorderedList)`
  margin-left: 0;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
`;

const projectData = [
  {
    title: "Game of Life",
    summary: "This project was done using the p5 library and studying the Game of Life wikapedia page",
    links: {
      github: "https://github.com/bgmad/game-of-life",
      deployed: "https://hardcore-keller-1992e8.netlify.app/"
    }
  },
  {
    title: "To Do App",
    summary: "This project was done using ReactJS along with the AntD component library.",
    links: {
      github: "https://github.com/bgmad/todo-app",
      deployed: "https://stupefied-swartz-c67f57.netlify.app/"
    }
  },
  {
    title: "Storie.ai",
    summary: "This was a project that my father initially asked me to work on. The first iteration of this page was to fade a quote in and out and display it for the appropriate amount of time. The current state of this page is a simple static page, made to be as resposive as possible. There are two seperate background images for portrain and landscape mode. This current page was done using vanilla ReactJS.",
    links: {
      github: "https://github.com/storie-ai/mock-client",
      deployed: "https://storie.ai/"
    }
  },
  {
    title: "Rick And Morty API Project",
    summary: "This project was one of the first experiences I had working with an open API. I worked on this during my time at the Lambda school.",
    links: {
      github: "https://github.com/bgmad/web-sprint-challenge-intro-to-react",
      deployed: "https://relaxed-keller-868bae.netlify.app/"
    }
  },
  {
    title: "Animation Study",
    summary: "This was one of my first attempts at animation. This was done using css animations.",
    links: {
      github: "https://github.com/bgmad/css-animations",
      deployed: "https://clever-easley-dbcad2.netlify.app/"
    }
  }
];

const StyledDivider = styled(Divider)`
  background-color: black;
  align-self: center;
`;

const Projects = () => {
  return (
    <VStack w="100%" align="start">
      <Center w="100%">
        <Heading as="h2" size="xl">
          Projects
        </Heading>
      </Center>
      <Flex align="start" width="85%" alignContent="flex-start">
        <StyledUnorderedList styleType="none" w="100%" spacing={2}>
          {projectData.map((p, i) => (
            <VStack key={`${i}-${p.title}`}>
              <ProjectCard project={p} />
              {projectData.length - 1 === i ? null : (
                <StyledDivider variant="dashed" />
              )}
            </VStack>
          ))}
        </StyledUnorderedList>
      </Flex>
    </VStack>
  );
};

export default Projects;
